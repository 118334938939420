<template>
  <div>
    <PageLoader v-bind:storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#30B868" left :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-dialog
      v-model="cropImageDialog"
      persistent
      :width="
        $vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'
          ? '100vw'
          : $vuetify.breakpoint.name == 'md'
          ? '80vw'
          : $vuetify.breakpoint.name == 'lg'
          ? '50vw'
          : '40vw'
      "
    >
      <v-card>
        <v-layout wrap justify-center pa-2>
          <v-flex xs12>
            <ImageCropper
              :image="currentImage"
              :key="currentImage"
              :cropwidth="1728"
              :cropheight="781"
              @stepper="imageCropper"
            />
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="cropImageDialogGallery"
      persistent
      :width="
        $vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'
          ? '100vw'
          : $vuetify.breakpoint.name == 'md'
          ? '80vw'
          : $vuetify.breakpoint.name == 'lg'
          ? '50vw'
          : '40vw'
      "
    >
      <v-card>
        <v-layout wrap justify-center pa-2>
          <v-flex xs12>
            <ImageCropper
              :image="galleryImage"
              :key="galleryImage"
              :cropwidth="380"
              :cropheight="398"
              @stepper="imageCropperGallery"
            />
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <v-dialog
      max-width="800px"
      v-model="imageDialog"
      transition="dialog-transition"
    >
      <template v-slot:activator="{ on }">
        <v-icon v-on="on" style="cursor: pointer" color="#ffffff"
          >mdi-square-edit-outline</v-icon
        >
      </template>
      <v-card width="800">
        <v-layout pa-2 wrap>
          <v-flex xs12 lg12>
            <v-layout py-5 wrap>
              <v-flex xs12>
                <span
                  style="
                    font-family: opensanssemibold;
                    font-size: 18px;
                    color: #1c1c1c;
                    letter-spacing: 0.54px;
                  "
                >
                  Profile Picture
                </span>
              </v-flex>
            </v-layout>
            <v-layout wrap>
              <v-flex xs12 pa-2 style="font-family: poppinsmedium">
                <ImageComp
                  :singleImage="appUser.sellerId.profilePicture"
                  @stepper="winStepper"
                  :width="494"
                  :height="324"
                  :heading="'Upload Profile Image (494 * 324)'"
                  :componentType="'profileImage'"
                />
              </v-flex>
            </v-layout>
          </v-flex>

          <v-flex xs12 lg12>
            <v-layout py-5 wrap>
              <v-flex xs12>
                <span
                  style="
                    font-family: opensanssemibold;
                    font-size: 18px;
                    color: #1c1c1c;
                    letter-spacing: 0.54px;
                  "
                >
                  Cover Image
                </span>
              </v-flex>
            </v-layout>
            <v-flex xs12 pa-2>
              <span style="font-family: poppinsmedium"
                >Upload Banner Images (1728 * 781)
              </span>
              <v-card class="mx-auto" outlined>
                <v-layout wrap pa-3>
                  <v-flex xs12 md12 lg12>
                    <v-layout wrap>
                      <v-flex lg1> </v-flex>
                      <v-flex xs12>
                        <v-layout wrap justify-center fill-height>
                          <v-flex
                            xs12
                            sm6
                            v-for="(list, l) in appUser.sellerId.caroselImage"
                            :key="l"
                            pa-1
                          >
                            <v-img :src="mediaUURL + list">
                              <template v-slot:placeholder>
                                <ImageLoader />
                              </template>
                              <v-layout wrap>
                                <v-flex text-right pa-0>
                                  <v-avatar color="#FF3434" size="15">
                                    <v-icon
                                      color="#FFF"
                                      small
                                      @click="removePhoto(l)"
                                    >
                                      mdi-close
                                    </v-icon>
                                  </v-avatar>
                                </v-flex>
                              </v-layout>
                            </v-img>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex
                        xs12
                        sm3
                        v-for="(item, i) in imageArray"
                        :key="i"
                        pa-1
                      >
                        <v-img :src="item" height="50px" contain>
                          <v-layout wrap>
                            <v-flex text-right pa-0>
                              <v-avatar color="#FF3434" size="15">
                                <v-icon
                                  color="#FFF"
                                  small
                                  @click="removeImageArray(i)"
                                >
                                  mdi-close
                                </v-icon>
                              </v-avatar>
                            </v-flex>
                          </v-layout>
                        </v-img>
                      </v-flex>
                    </v-layout>

                    <div class="dottedline"></div>
                    <v-layout wrap pt-1 align-center>
                      <v-flex xs10 md6 lg12 align-self-center text-center pb-2>
                        <v-icon
                          size="25px"
                          id="pro_pic"
                          @click="$refs.files.click()"
                          >mdi-plus</v-icon
                        >
                        <span style="color: #828282; font-size: 14px"
                          >Upload your banner Image</span
                        >
                        <input
                          v-show="false"
                          accept="image/*"
                          id="file"
                          ref="files"
                          type="file"
                          @change="uploadImages"
                        />
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-flex>
        </v-layout>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            tile
            color="#757575"
            depressed
            :ripple="false"
            class="text-capitalize"
            @click="
              imageDialog = false;
              proPicImg = '';
              coverPicImg = '';
            "
          >
            Cancel
          </v-btn>
          <v-btn
            tile
            dark
            color="#30B868"
            depressed
            class="text-capitalize"
            :ripple="false"
            @click="CheckDialog()"
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-layout wrap justify-start>
      <v-flex xs12 xl11>
        <v-layout wrap justify-center pb-4 px-2 px-sm-4>
          <v-flex xs12>
            <v-layout wrap justify-start>
              <v-flex xs7 sm3 md3 lg2 xl2>
                <v-layout wrap justify-center>
                  <v-flex xs3 sm2 text-left align-self-center>
                    <v-img
                      src="./../../assets/greenlogo.png"
                      contain
                      height="15px"
                      alt="EcoShop"
                    ></v-img>
                  </v-flex>
                  <v-flex xs9 sm10 text-left pl-2 align-self-center>
                    <span
                      style="
                        font-family: poppinssemibold;
                        font-size: 18px;
                        color: #1c1c1c;
                      "
                    >
                      Profile
                    </span>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex pt-2 xs12>
            <!-- Banner Section -->
            <v-flex xs12 lg12 md12>
              <v-carousel
                v-model="slideNo"
                continuous
                cycle
                :interval="slideInterval"
                :show-arrows="false"
                hide-delimiter-background
                delimiter-icon="mdi-minus"
                :height="sliderHeight"
              >
                <v-carousel-item
                  v-for="(data, d) in appUser.sellerId.caroselImage"
                  :key="d"
                >
                  <v-card flat>
                    <v-img :src="mediaUURL + data" contain alt="Cover Image">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="#FF4343"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                      <v-layout justify-space-between fill-height wrap>
                        <v-flex pb-7 align-self-end xs2>
                          <v-avatar size="80">
                            <v-img
                              :src="mediaUURL + appUser.sellerId.profilePicture"
                              cover
                              :alt="appUser.name"
                            >
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height ma-0"
                                  align="center"
                                  justify="center"
                                >
                                  <v-progress-circular
                                    indeterminate
                                    color="#FF4343"
                                  ></v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </v-avatar>
                        </v-flex>
                      </v-layout>
                    </v-img>
                  </v-card>
                </v-carousel-item>
              </v-carousel>
            </v-flex>
          </v-flex>
          <v-flex xs12 pl-2 v-if="appUser.sellerId">
            <v-layout wrap justify-space-between>
              <v-flex xs6 text-left>
                <v-layout wrap justify-start>
                  <v-flex xs12>
                    <span
                      style="
                        font-family: kumbhsansbold;
                        font-size: 15px;
                        color: #000000;
                      "
                    >
                      {{ appUser.sellerId.shopName }}
                    </span>
                  </v-flex>
                  <v-flex xs12 v-if="appUser.sellerId.address">
                    <v-icon color="#000000" size="12px">mdi-map-marker</v-icon>
                    <span
                      style="
                        font-family: kumbhsansregular;
                        font-size: 12px;
                        color: #787676;
                      "
                    >
                      {{ appUser.sellerId.address.name }},&nbsp;
                      {{ appUser.sellerId.address.place }},&nbsp;
                      {{ appUser.sellerId.address.district }}
                    </span>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs6 text-right>
                <!-- <v-flex pr-3 pb-3 text-right align-self-end xs1 lg1> -->
                <!-- Dialogue -->
                <v-icon
                  @click="imageDialog = true"
                  style="cursor: pointer"
                  color="#000"
                  >mdi-square-edit-outline</v-icon
                >
                <!-- </v-flex> -->
                <span class="pr-1" v-if="appUser.sellerId">
                  <a :href="appUser.sellerId.socialmedialinks.facebook">
                    <v-icon color="#9D9C9C" size="22px">mdi-facebook</v-icon>
                  </a>
                </span>
                <span class="pl-1" v-if="appUser.sellerId">
                  <a :href="appUser.sellerId.socialmedialinks.instagram">
                    <v-icon color="#9D9C9C" size="22px">mdi-instagram</v-icon>
                  </a>
                </span>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12>
            <!-- Profile Details -->
            <v-layout wrap justify-center pt-2>
              <v-flex xs12 sm8 md8 lg6 xl4>
                <v-layout wrap>
                  <v-flex text-left xs12 lg12>
                    <span class="ecoshopinputlabel pl-0">
                      Name of the Company/Shop
                    </span>
                    <v-text-field
                      color="#30B868"
                      dense
                      v-model="sellerData.shopName"
                      placeholder="Name of Shop"
                      outlined
                    ></v-text-field>
                  </v-flex>
                  <v-flex text-left xs12 lg12>
                    <span class="ecoshopinputlabel pl-0">Address Line</span>
                    <v-card class="mx-auto mb-6" outlined>
                      <v-layout wrap pa-3 v-if="sellerData.address">
                        <v-flex xs12 md12 lg12>
                          <v-layout wrap justify-start>
                            <v-flex xs12 px-2>
                              <span class="ecoshopinputlabel pl-0">
                                Address
                              </span>
                              <v-text-field
                                ref="streetAddress"
                                color="#30B868"
                                placeholder="Address"
                                dense
                                v-model="sellerData.address.name"
                                outlined
                              ></v-text-field>
                            </v-flex>
                            <v-flex xs6 px-2>
                              <span class="ecoshopinputlabel pl-0">
                                Place
                              </span>
                              <v-text-field
                                ref="place"
                                color="#30B868"
                                placeholder="Place"
                                dense
                                v-model="sellerData.address.place"
                                outlined
                              ></v-text-field>
                            </v-flex>

                            <v-flex xs6 px-2>
                              <span class="ecoshopinputlabel pl-0"
                                >District</span
                              >
                              <v-text-field
                                ref="district"
                                color="#30B868"
                                placeholder="District"
                                dense
                                v-model="sellerData.address.district"
                                outlined
                              ></v-text-field>
                            </v-flex>
                            <!-- <v-flex xs6 px-2>
                              <span class="ecoshopinputlabel pl-0">
                                District
                              </span>
                              <v-text-field
                                ref="district"
                                color="#30B868"
                                placeholder="District"
                                dense
                                v-model="seller.district"
                                outlined
                              ></v-text-field>
                            </v-flex>

                            <v-flex xs6 px-2>
                              <span class="ecoshopinputlabel pl-0">State</span>
                              <v-text-field
                                ref="state"
                                color="#30B868"
                                placeholder="State"
                                dense
                                v-model="seller.state"
                                outlined
                              ></v-text-field>
                            </v-flex>
                            <v-flex xs6 px-2>
                              <span class="ecoshopinputlabel pl-0">
                                Pincode
                              </span>
                              <v-text-field
                                type="number"
                                ref="pincode"
                                color="#30B868"
                                placeholder="Pincode"
                                dense
                                required
                                v-model="seller.pincode"
                                outlined
                              ></v-text-field>
                            </v-flex> -->
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>
                  <v-flex pt-4 text-left xs12 lg12>
                    <span class="ecoshopinputlabel">Bank Details</span>
                    <v-card class="mx-auto" outlined>
                      <v-layout wrap pa-3>
                        <v-flex xs12 md12 lg12>
                          <span class="ecoshopinputlabel pl-0">Bank</span>
                          <v-text-field
                            ref="bank"
                            color="#30B868"
                            placeholder="Bank"
                            dense
                            :rules="[rules.required]"
                            v-model="seller.bankname"
                            disabled
                          ></v-text-field>
                          <span class="ecoshopinputlabel pl-0">
                            Account Holder
                          </span>
                          <v-text-field
                            ref="accountholder"
                            color="#30B868"
                            placeholder="Account Holder"
                            dense
                            :rules="[rules.required]"
                            v-model="seller.accountholder"
                            disabled
                          ></v-text-field>

                          <span class="ecoshopinputlabel pl-0">
                            Account Number
                          </span>
                          <v-text-field
                            ref="accountnumber"
                            color="#30B868"
                            placeholder="Account Number"
                            dense
                            :rules="[rules.required]"
                            v-model="seller.accountnumber"
                            disabled
                          ></v-text-field>
                          <v-layout wrap>
                            <v-flex xs6 px-2>
                              <span class="ecoshopinputlabel pl-0">
                                IFSC Code
                              </span>
                              <v-text-field
                                ref="ifsc"
                                color="#30B868"
                                placeholder="IFSC Code"
                                dense
                                :rules="[rules.required]"
                                v-model="seller.ifsccode"
                                disabled
                              ></v-text-field>
                            </v-flex>

                            <v-flex xs6 px-2>
                              <span class="ecoshopinputlabel pl-0">Branch</span>
                              <v-text-field
                                ref="branch"
                                color="#30B868"
                                placeholder="Branch"
                                dense
                                required
                                :rules="[rules.required]"
                                v-model="seller.branch"
                                disabled
                              ></v-text-field>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>
                  <v-flex pt-4 text-left xs12>
                    <span class="ecoshopinputlabel">Email</span>
                    <v-text-field
                      ref="email"
                      color="#30B868"
                      placeholder="Email Id"
                      outlined
                      dense
                      :rules="[rules.required]"
                      v-model="seller.email"
                    ></v-text-field>
                  </v-flex>
                  <v-flex text-left xs12 lg12>
                    <span class="ecoshopinputlabel">Phone number</span>
                    <v-text-field
                      ref="phone"
                      color="#30B868"
                      placeholder="Whatsapp number"
                      outlined
                      dense
                      readonly
                      :rules="[rules.required]"
                      type="number"
                      v-model="seller.phone"
                    >
                      <template v-slot:prepend-inner>
                        <v-layout wrap justify-center>
                          <v-flex pt-1 xs18 class="align-self-center">
                            <span
                              style="
                                font-family: poppinsregular;
                                font-size: 14px;
                                color: #000;
                              "
                              >+91</span
                            >
                          </v-flex>
                          <v-flex pl-2 xs1>
                            <v-divider vertical></v-divider>
                          </v-flex>
                        </v-layout>
                        <v-card class="mx-auto" outlined></v-card>
                      </template>
                    </v-text-field>
                  </v-flex>
                  <v-flex text-left xs12 lg12>
                    <span class="ecoshopinputlabel">Whatsapp number</span>
                    <v-text-field
                      ref="phone"
                      color="#30B868"
                      placeholder="Whatsapp number"
                      outlined
                      dense
                      :rules="[rules.required]"
                      type="number"
                      v-model="seller.whatsapp"
                    >
                      <template v-slot:prepend-inner>
                        <v-layout wrap justify-center>
                          <v-flex pt-1 xs18 class="align-self-center">
                            <span
                              style="
                                font-family: poppinsregular;
                                font-size: 14px;
                                color: #000;
                              "
                              >+91</span
                            >
                          </v-flex>
                          <v-flex pl-2 xs1>
                            <v-divider vertical></v-divider>
                          </v-flex>
                        </v-layout>
                        <v-card class="mx-auto" outlined></v-card>
                      </template>
                    </v-text-field>
                  </v-flex>
                  <v-flex text-left xs12 lg12>
                    <span class="ecoshopinputlabel">Instagram URL</span>
                    <v-text-field
                      ref="email"
                      color="#30B868"
                      placeholder="Instagram URL"
                      outlined
                      dense
                      v-model="seller.instagram"
                    ></v-text-field>
                  </v-flex>
                  <v-flex text-left xs12 lg12>
                    <span class="ecoshopinputlabel">Facebook URL</span>
                    <v-text-field
                      ref="email"
                      color="#30B868"
                      placeholder="Facebook URL"
                      outlined
                      dense
                      v-model="seller.facebook"
                    ></v-text-field>
                  </v-flex>
                  <v-flex text-left xs12 lg12>
                    <span class="ecoshopinputlabel">GST Number</span>
                    <v-text-field
                      ref="gst"
                      color="#30B868"
                      placeholder="GST Number"
                      outlined
                      dense
                      v-model="seller.sellerId.gstNumber"
                    ></v-text-field>
                  </v-flex>
                  <v-flex text-left xs12 lg12>
                    <span class="ecoshopinputlabel">Our Story</span>
                    <div id="app" v-if="seller.sellerId">
                      <vue-editor v-model="seller.sellerId.story"></vue-editor>
                    </div>
                  </v-flex>
                  <v-flex xs12 text-left pt-5>
                    <span class="ecoshopinputlabel"
                      >Upload Gallery Images (380 * 398)
                    </span>
                    <v-card class="mx-auto" outlined>
                      <v-layout wrap pa-3>
                        <v-flex xs12 md12 lg12>
                          <v-layout wrap>
                            <v-flex lg1> </v-flex>
                            <v-flex xs12 v-if="seller.sellerId">
                              <v-layout wrap justify-center fill-height>
                                <v-flex
                                  xs12
                                  sm6
                                  v-for="(data, d) in seller.sellerId.gallery"
                                  :key="d"
                                  pa-1
                                >
                                  <v-img :src="mediaUURL + data">
                                    <template v-slot:placeholder>
                                      <ImageLoader />
                                    </template>
                                    <v-layout wrap>
                                      <v-flex text-right pa-0>
                                        <v-avatar color="#FF3434" size="15">
                                          <v-icon
                                            color="#FFF"
                                            small
                                            @click="removePhotoGallery(d)"
                                          >
                                            mdi-close
                                          </v-icon>
                                        </v-avatar>
                                      </v-flex>
                                    </v-layout>
                                  </v-img>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                            <v-flex
                              xs12
                              sm3
                              v-for="(img, m) in imageArrayGallery"
                              :key="m"
                              pa-1
                            >
                              <v-img :src="img" height="50px" contain>
                                <v-layout wrap>
                                  <v-flex text-right pa-0>
                                    <v-avatar color="#FF3434" size="15">
                                      <v-icon
                                        color="#FFF"
                                        small
                                        @click="removeImageArrayGallery(i)"
                                      >
                                        mdi-close
                                      </v-icon>
                                    </v-avatar>
                                  </v-flex>
                                </v-layout>
                              </v-img>
                            </v-flex>
                          </v-layout>

                          <div class="dottedline"></div>
                          <v-layout wrap pt-1 align-center>
                            <v-flex
                              xs10
                              md6
                              lg12
                              align-self-center
                              text-center
                              pb-2
                            >
                              <v-icon
                                size="25px"
                                id="pro_pic"
                                @click="$refs.files.click()"
                                >mdi-plus</v-icon
                              >
                              <span style="color: #828282; font-size: 14px"
                                >Upload your gallery Image</span
                              >
                              <input
                                v-show="false"
                                accept="image/*"
                                id="file"
                                ref="files"
                                type="file"
                                @change="uploadImagesGallery"
                              />
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>
                </v-layout>
                <v-layout wrap justify-center>
                  <v-flex xs12 pt-2 pb-4>
                    <v-btn
                      tile
                      block
                      dark
                      color="#30B868"
                      depressed
                      class="text-capitalize"
                      @click.prevent="validateInput"
                      :ripple="false"
                      >Update</v-btn
                    >
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import { VueEditor } from "vue2-editor";
import ImageComp from "@/components/Common/singleImage";
import ImageCropper from "@/components/Common/imageCropper";
export default {
  components: {
    VueEditor,
    ImageCropper,
    ImageComp,
  },
  data() {
    return {
      seller: {},
      sellerData: {},
      slideNo: 0,
      slideInterval: 5000,
      imageDialog: false,
      proPicImg: null,
      proPic: null,
      idproofpic: null,
      coverPicture: null,
      coverPicImg: null,
      coverPic: null,
      profileImage: "",
      imageArray: [],
      imageArrayGallery: [],
      Images: new FormData(),
      formData: new FormData(),
      ImagesGallery: new FormData(),
      formDataGallery: new FormData(),
      selectedFiles: null,
      selectedFilesGallery: "",
      currentImage: "",
      galleryImage: "",
      cropImageDialog: false,
      cropImageDialogGallery: false,
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: "",
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
      },
    };
  },
  computed: {
    appUser() {
      return this.$store.state.userData;
    },
    appType() {
      return this.$store.state.userType;
    },
    sliderHeight() {
      var height = "100%";
      if (this.$vuetify.breakpoint.xl) height = "100%";
      if (this.$vuetify.breakpoint.lg) height = "100%";
      if (this.$vuetify.breakpoint.md) height = "100%";
      if (this.$vuetify.breakpoint.sm) height = "100%";
      if (this.$vuetify.breakpoint.xs) height = "100%";
      return height;
    },
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    imageCropper(payload) {
      this.cropImageDialog = payload.dialog;
      if (payload.image) {
        this.collectImages(payload.image);
      }
    },
    imageCropperGallery(payload) {
      this.cropImageDialogGallery = payload.dialog;
      if (payload.image) {
        this.collectImagesGallery(payload.image);
      }
    },
    winStepper(window_data) {
      if (window_data.type == "profileImage") {
        this.profileImage = window_data.selectedFiles;
      }
    },
    removeUploadPropic() {
      this.seller.image = "";
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/storeUser/profile",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.appLoading = false;
            this.seller = response.data.user;
            this.sellerData = this.seller.sellerId;
            // this.idproofpic = this.seller.seller.idproof[0];
            this.seller.accountnumber =
              this.seller.sellerId.bankDetails.accountNo;
            this.seller.accountholder =
              this.seller.sellerId.bankDetails.accountHolder;
            this.seller.bankname = this.seller.sellerId.bankDetails.bank;
            this.seller.ifsccode = this.seller.sellerId.bankDetails.ifsc;
            this.seller.branch = this.seller.sellerId.bankDetails.branch;
            this.seller.facebook =
              this.seller.sellerId.socialmedialinks.facebook;
            this.seller.whatsapp =
              this.seller.sellerId.socialmedialinks.whatsapp;
            this.seller.instagram =
              this.seller.sellerId.socialmedialinks.instagram;
            // this.seller.tag=this.seller.tags
          } else {
            this.appLoading = false;
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    CheckDialog() {
      if (this.profileImage) {
        this.uploadImage();
      }
      if (this.imageArray.length > 0) {
        this.uploadAllImages();
      }
      this.imageDialog = false;
    },
    validateInput() {
      if (!this.seller.email) {
        this.msg = "Please provide Email id";
        this.showSnackBar = true;
        return;
      }
      if (!this.seller.phone) {
        this.msg = "Please provide the phone number";
        this.showSnackBar = true;
        return;
      }

      this.editProfile();
    },
    uploadImages(event) {
      if (this.imageArray.length < 4) {
        this.currentImage = URL.createObjectURL(event.target.files[0]);
        this.cropImageDialog = true;
      } else {
        this.msg = "Maximum image limit exceeded!";
        this.showSnackBar = true;
        return;
      }
    },
    collectImages(image) {
      this.selectedFiles = image;
      this.Images.append("image", this.selectedFiles);
      this.formData.append("images", this.selectedFiles);
      var img = new Image();
      img.src = window.URL.createObjectURL(image);
      img.onload = () => {
        const urls = URL.createObjectURL(image);
        this.imageArray.push(urls);
        if (this.imageArray) {
          this.image = this.imageArray[0];
        }
      };
    },
    removeImageArray(i) {
      var values = this.formData.getAll("photos");
      values.splice(i, 1);
      this.formData.set("photos", values);
      this.imageArray.splice(i, 1);
    },
    removePhoto(i) {
      var Data = {};
      this.appUser.sellerId.caroselImage.splice(i, 1);
      Data["position"] = i + 1;
      Data["sellerid"] = this.appUser.sellerId._id;
      axios({
        method: "POST",
        url: "/seller/remove/bannerImage/single",
        data: Data,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            // this.imageCount =
            //   this.product.photos.length + this.imageArray.length;
            this.showsnackbar = true;
            this.msg = "Deleted Successfully";
            this.getData();
          } else {
            this.msg = "Can't Delete Image";
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    removePhotoGallery(i) {
      var Data = {};
      this.seller.sellerId.gallery.splice(i, 1);
      Data["position"] = i + 1;
      Data["sellerid"] = this.appUser.sellerId._id;
      axios({
        method: "POST",
        url: "/seller/remove/galleryImage/single",
        data: Data,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            // this.imageCount =
            //   this.product.photos.length + this.imageArray.length;
            this.showsnackbar = true;
            this.msg = "Deleted Successfully";
            this.getData();
          } else {
            this.msg = "Can't Delete Image";
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    uploadAllImages() {
      this.appLoading = true;
      this.formData.append("id", this.appUser.sellerId._id);
      axios({
        method: "POST",
        url: "/seller/upload/bannerImage",
        data: this.formData,
        headers: {
          "Content-Type": "multipart/form-data",
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = "Updated Sucessfully";
            this.showsnackbar = true;
            location.reload()
            this.getData();
          } else {
            this.msg = "Can't Upload Images";
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    uploadImage() {
      let formData = new FormData();
      formData.append("id", this.appUser.sellerId._id);
      formData.append("image", this.profileImage);
      axios({
        method: "POST",
        url: "/seller/upload/profileImage",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.showsnackbar = true;
            this.msg = "Uploaded Successfully";
            location.reload()
            this.getData();
            this.dialog = false;
          } else {
            this.msg = "Can't Update";
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    removeImageArrayGallery(i) {
      var values = this.formDataGallery.getAll("photos");
      values.splice(i, 1);
      this.formDataGallery.set("photos", values);
      this.imageArrayGallery.splice(i, 1);
    },
    uploadImagesGallery(event) {
      console.log("hello");
      if (this.imageArrayGallery.length < 4) {
        this.galleryImage = URL.createObjectURL(event.target.files[0]);
        this.cropImageDialogGallery = true;
      } else {
        this.msg = "Maximum image limit exceeded!";
        this.showSnackBar = true;
        return;
      }
    },
    collectImagesGallery(image) {
      this.selectedFilesGallery = image;
      this.ImagesGallery.append("image", this.selectedFilesGallery);
      this.formDataGallery.append("images", this.selectedFilesGallery);
      var img = new Image();
      img.src = window.URL.createObjectURL(image);
      img.onload = () => {
        const urls = URL.createObjectURL(image);
        this.imageArrayGallery.push(urls);
        if (this.imageArrayGallery) {
          this.imageGallery = this.imageArrayGallery[0];
        }
      };
    },
    uploadAllGalleryImages() {
      this.appLoading = true;
      this.formDataGallery.append("id", this.appUser.sellerId._id);
      axios({
        method: "POST",
        url: "/seller/upload/galleryImage",
        data: this.formDataGallery,
        headers: {
          "Content-Type": "multipart/form-data",
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = "Updated Sucessfully";
            this.showSnackBar = true;
            location.reload()
            this.getData();
          } else {
            this.msg = "Can't Upload Images";
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    editProfile() {
      this.appLoading = true;
      var profileData = {};
      profileData["phone"] = this.seller.phone;
      profileData["whatsapp"] = this.seller.whatsapp;
      profileData["email"] = this.seller.email;
      profileData["instagram"] = this.seller.instagram;
      profileData["facebook"] = this.seller.facebook;
      profileData["id"] = this.appUser.sellerId._id;
      profileData["shopName"] = this.sellerData.shopName;
      profileData["address"] = this.sellerData.address;
      profileData["story"] = this.seller.sellerId.story;
      profileData["gstNumber"] = this.seller.sellerId.gstNumber;
      axios({
        method: "POST",
        url: "/seller/edit",
        data: profileData,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            if (this.imageArrayGallery.length > 0) {
              this.uploadAllGalleryImages();
            }
            this.msg = "Profile Updated";
            this.showSnackBar = true;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>